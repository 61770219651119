<template>
  <div>
    <v-app>
      <v-app-bar
        app
        light
        fixed
        clipped-left
        elevation="2"
        :style="{ background: $vuetify.theme.themes.light.background }"
      >
        <v-app-bar-nav-icon
          @click.stop="navIconAction()"
          class="mr-5"
        ></v-app-bar-nav-icon>
        <router-link
          :to="{ name: 'app-dashboard' }"
          class="d-flex align-center"
        >
          <img alt :src="appLongLogo" class="app-logo" />
        </router-link>
        <v-spacer></v-spacer>
        <CalendarWork v-if="hasWorkloadAccess" />
        <AddWork v-if="hasWorkloadAccess" />
        <TimeLogger v-if="hasWorkloadAccess" />

        <!-- <v-menu offset-y left transition="slide-y-transition">
          <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: onTooltip }">
                <v-btn
                  icon
                  v-on="{ ...onTooltip, ...onMenu }"
                  v-bind="attrsMenu"
                >
                  <v-badge color="accent" dot>
                    <v-icon>mdi-bell</v-icon>
                  </v-badge>
                </v-btn>
              </template>
              <span>Notifications</span>
            </v-tooltip>
          </template>
          <v-list three-line class="pt-0 pb-0" width="450">
            <v-subheader class="font-weight-bold">Notifications</v-subheader>
            <v-divider></v-divider>
            <v-list-item link>
              <v-list-item-avatar dark>
                <v-icon>mdi-lighthouse-on</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Sample Notification</v-list-item-title>
                <v-list-item-subtitle>
                  This is a sample notification and is only an example of what
                  one might look like
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>5 mins</v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu> -->
        <!-- <v-menu offset-y left transition="slide-y-transition">
          <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: onTooltip }">
                <v-btn
                  icon
                  v-on="{ ...onTooltip, ...onMenu }"
                  v-bind="attrsMenu"
                >
                  <v-badge color="accent" dot>
                    <v-icon>mdi-message-text</v-icon>
                  </v-badge>
                </v-btn>
              </template>
              <span>Messages</span>
            </v-tooltip>
          </template>
          <v-list three-line class="pt-0 pb-0" width="450">
            <v-subheader class="d-flex align-center">
              <div class="font-weight-bold">Messages</div>
              <v-spacer></v-spacer>
              <v-btn outlined x-small>View All</v-btn>
            </v-subheader>
            <v-divider></v-divider>
            <v-list-item link>
              <v-list-item-avatar color="accent">
                <span class="white--text">ED</span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Eddie Dunkerley</v-list-item-title>
                <v-list-item-subtitle>
                  Hello! This is an example message and not actually real 🙂
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>15 mins</v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu> -->
        <v-tooltip bottom v-if="sumOfApps > 1">
          <template v-slot:activator="{ on }">
            <v-chip class="ml-5" @click="switchAppDialog = true" v-on="on">
              <v-icon small class="mr-1">mdi-apps</v-icon>
              {{ appName ? appName : "Choose App" }}
            </v-chip>
          </template>
          <span>Switch App</span>
        </v-tooltip>
        <v-tooltip bottom v-if="sumOfApps === 1">
          <template v-slot:activator="{ on }">
            <v-chip class="ml-5" :to="{ name: 'app-selector' }" v-on="on">
              <v-icon small class="mr-1">mdi-view-dashboard</v-icon>
              {{ appName }}
            </v-chip>
          </template>
          <span>Return To Dashboard</span>
        </v-tooltip>
        <v-menu :offset-y="true">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" class="ml-5">
              <VPAvatar />
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="sumOfApps > 1" @click="switchAppDialog = true">
              <v-list-item-icon>
                <v-icon>mdi-apps</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Switch App</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item link>
              <v-list-item-icon>
                <v-icon>mdi-lifebuoy</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Help & Support</v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-icon>
                <v-icon>mdi-bug</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Bug Reporter</v-list-item-title>
            </v-list-item> -->
            <v-list-item :to="{ name: 'account-settings' }">
              <v-list-item-icon>
                <v-icon>mdi-account-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Account Settings</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="hasPermission('view-app-settings', $route.params.id)"
              :to="{ name: 'app-settings' }"
            >
              <v-list-item-icon>
                <v-icon>mdi-brush</v-icon>
              </v-list-item-icon>
              <v-list-item-title>App Settings</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-navigation-drawer
        :mini-variant="drawer"
        v-model="drawerOpen"
        app
        clipped
        color="grey darken-4"
      >
        <v-list class="navigation-list" dark>
          <v-list-item class="mb-3">
            <v-list-item-content>
              <v-list-item-title class="title">
                {{ user.full_name }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <div v-for="item in sidebarItems" v-bind:key="item.name">
            <v-list-item v-if="!item.hasSubmodules" :to="item.to">
              <v-list-item-action>
                <v-icon color="white">{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content class="white--text">
                <v-list-item-title class="nav-link-title">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-else
              :prepend-icon="item.icon"
              class="white--text"
              active-class="white--text"
            >
              <template v-slot:activator>
                <v-list-item-title class="nav-link-title">
                  {{ item.title }}
                </v-list-item-title>
              </template>
              <v-list dense class="pt-0 pb-0">
                <v-list-item
                  v-for="subItem in item.submodules"
                  v-bind:key="subItem.title"
                  :to="subItem.to"
                  link
                  class="white--text"
                >
                  <v-list-item-title class="nav-link-title">{{
                    subItem.title
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-list-group>
          </div>
        </v-list>

        <template v-slot:append v-if="showReturnToApp">
          <v-list class="navigation-list" dark>
            <v-list-item :to="{ name: 'app-selector' }">
              <v-list-item-action>
                <v-icon color="white">mdi-arrow-left-circle</v-icon>
              </v-list-item-action>
              <v-list-item-content class="white--text">
                <v-list-item-title class="nav-link-title">
                  Return to
                  {{ sumOfApps === 1 ? "app" : "apps" }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>

        <template v-slot:append v-if="devNotice.show">
          <v-list
            :color="devNotice.color"
            dark
            dense
            disabled
            class="dev-notice"
          >
            <v-list-item :color="devNotice.color" dense>
              <v-list-item-action>
                <v-icon small>mdi-wrench</v-icon>
              </v-list-item-action>
              <v-list-item-content small>
                {{ devNotice.text }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer>

      <v-main class="app-content">
        <v-progress-linear
          :active="this.$store.getters.showLoader"
          indeterminate
          fixed
        ></v-progress-linear>
        <v-container fluid>
          <router-view />
        </v-container>
        <v-tooltip left v-if="isOffline">
          <template v-slot:activator="{ on }">
            <v-btn fab bottom right fixed color="error" v-on="on">
              <v-icon>mdi-cloud-off-outline</v-icon>
            </v-btn>
          </template>
          <span>Network is Offline</span>
        </v-tooltip>
      </v-main>

      <v-footer color="white" light app inset class="font-weight-light caption">
        <span>
          Copyright &copy; {{ new Date().getFullYear() }} Vital Hike. All rights
          reserved.
        </span>
        <v-spacer></v-spacer>
        <span>
          <v-icon small class="red--text">mdi-image-filter-hdr</v-icon>Viewpoint
          By Vital Hike - Bringing Big Data to Small Businesses
        </span>
      </v-footer>
    </v-app>

    <v-dialog v-if="sumOfApps > 1" v-model="switchAppDialog" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Switch App</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <v-list class="pa-0">
            <v-list-item
              v-for="app in apps"
              v-bind:key="app.name"
              nav
              :to="{ name: 'app-dashboard', params: { id: app.id } }"
              v-on:click="switchAppDialog = false"
            >
              <v-list-item-icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ app.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text v-on:click="switchAppDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <IdleModal />

    <GlobalSnackbar />
  </div>
</template>

<script>
import IdleModal from "@/views/components/IdleModal";
import VPAvatar from "@/views/components/VPAvatar";
import TimeLogger from "@/views/components/TimeLogger";
import AddWork from "@/views/components/AddWork";
import CalendarWork from "@/views/components/CalendarWork";
import GlobalSnackbar from "@/views/components/GlobalSnackbar";
import VPLogo from "@/assets/long-logo.svg";

export default {
  props: {
    source: String,
    sidebarItems: Array,
    userInitials: String,
    user: {
      type: Object,
      required: true,
    },
    appName: {
      type: String,
      required: true,
    },
    appLongLogo: {
      type: String,
      required: true,
    },
    sumOfApps: Number,
    apps: Array,
    showReturnToApp: {
      type: Boolean,
      default: false,
    },
    hasWorkloadAccess: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    IdleModal,
    VPAvatar,
    TimeLogger,
    AddWork,
    CalendarWork,
    GlobalSnackbar,
  },

  data() {
    return {
      drawer: false,
      drawerOpen: null,
      switchAppDialog: false,
      devNotice: {
        show: false,
        color: null,
        text: null,
      },
      isOffline: false,
      logo: VPLogo,
    };
  },

  mounted() {
    if (process.env.VUE_APP_STAGE === "local") {
      this.devNotice.show = true;
      this.devNotice.color = "red";
      this.devNotice.text = "Local";
    } else if (process.env.VUE_APP_STAGE === "development") {
      this.devNotice.show = true;
      this.devNotice.color = "blue";
      this.devNotice.text = "Development";
    } else if (process.env.VUE_APP_STAGE === "staging") {
      this.devNotice.show = true;
      this.devNotice.color = "green";
      this.devNotice.text = "Staging";
    }

    if (this.$vuetify.breakpoint.mdAndDown) {
      this.drawerOpen = false;
    } else {
      this.drawerOpen = true;
    }
  },

  methods: {
    navIconAction: function () {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.drawerOpen = !this.drawerOpen;
      } else {
        this.drawer = !this.drawer;
      }
    },
    logout: function () {
      this.$store.dispatch("logout");
    },
  },

  created() {
    this.setTitle(this.appName);
  },

  watch: {
    appName: function (name) {
      this.setTitle(name);
    },
  },

  computed: {
    appId() {
      return this.$route.params.id;
    },
  },

  beforeDestroy() {
    this.setTitle();
  },
};
</script>

<style>
.app-logo {
  width: 167px;
  height: 20px;
  object-fit: contain;
  object-position: left;
}
.app-swapper {
  text-transform: none;
  letter-spacing: 0px;
  font-weight: normal;
}
.app-content {
  background-color: #fff;
}
.nav-link-title {
  font-weight: 300;
}
.navigation-list {
  background-color: #212121 !important;
}
.dev-notice {
  border-radius: 0px;
  padding: 0px;
}
</style>
